import {
    User,
    UserManager,
    UserProfile as Profile,
    WebStorageStateStore,
    InMemoryWebStorage,
    UserManagerSettings,
} from 'oidc-client-ts';
import join from 'lodash/fp/join';
import { mapUserProfile } from './userProfile';
import { config } from '../../config';
import { jwtDecode } from 'jwt-decode';
import { AccessToken, OAuthConfig, UserProfile } from '../types';

const trace = !import.meta.env.PROD ? (...args: any[]) => console.log(`[oidcLogin]`, ...args) : () => {};

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

export const isRunningInIframe = () => {
    try {
        return window.self !== window.top;
    } catch {
        return true;
    }
};

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.signinSilent().catch((error: Error) => {
        if (error.message === 'login_required') {
            oauthConfig.onSessionExpired();
        } else {
            setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
        }
    });
};

export interface SessionRenewedResult {
    accessToken: AccessToken;
    idToken: Profile;
    profile: UserProfile;
    locale: string;
}

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
    accessToken: result.access_token,
    idToken: result.profile,
    locale: result.profile?.locale ?? 'en-GB',
    profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
    const redirectUri = config.login.redirectUri;
    const silentRedirectUri = config.login.silentRedirectUri;

    const settings: UserManagerSettings = {
        authority: `${config.login.authority}`,
        client_id: `${config.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: `${redirectUri}`,
        response_type: 'code',
        scope: join(' ', config.login.oauthScope),
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
        includeIdTokenInSilentRenew: false,
        automaticSilentRenew: true,
        staleStateAgeInSeconds: 600,
        userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
        monitorSession: !isRunningInIframe(),
    };

    trace('oidc.auth.settings', settings);

    return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.events.addUserLoaded((user) => {
        trace('oidc.signinSilent success!');
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
    });

    userManager.events.addUserUnloaded(() => {
        oauthConfig.onSessionExpired();
    });

    userManager.events.addSilentRenewError((error) => {
        trace('oidc.silentRenewError', error);
        retrySigninSilent(oauthConfig, userManager);
    });

    userManager.events.addUserSignedOut((...args) => {
        trace('oidc.userSignedOut', ...args);
        oauthConfig.onSessionExpired();
    });

    return userManager;
};

export const configureMockUserManager = ({ onSessionRenewed }: OAuthConfig) => {
    console.warn(`[feature/login/oidc-session] Using mocked authorization due to config setting`);

    const signinSilent = () => {
        const userSettings = {
            access_token: 'valid-mocked-oauth-bogus-token',
            profile: {
                iss: 'Issuer Identifier',
                sub: 'prod-rio-users:mock-user-id',
                aud: 'Audience(s): client_id',
                exp: 10,
                iat: 5,
                account: 'mockaccount',
                azp: 'test-client',
                email: 'test@example.com',
                family_name: 'Client',
                given_name: 'Test',
                name: 'Test Client',
                username: 'preferred_username',
                locale: config.login.mockLocale,
                tenant: config.login.mockTenant,
                rawValue: 'fake-id-token',
            },
            id_token: 'fake-id-token',
            session_state: 'session_state',
            refresh_token: 'refresh_token',
            token_type: 'token_type',
            scope: 'scope',
            expires_at: 100000,
            state: 'state',
        };

        const user = new User(userSettings);
        onSessionRenewed(adaptPublishedInfo(user));
        return Promise.resolve();
    };
    const clearStaleState = () => {
        console.log('[userManager] clearStaleState called');
        return Promise.resolve();
    };

    const getUser = () => {
        console.log('[userManager] getUser called');
        return Promise.resolve(null);
    };

    return { signinSilent, clearStaleState, getUser } as unknown as UserManager;
};
